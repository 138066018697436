<template>
    <!-- <v-card class="pa-5"> -->
        <div>

            <!-- <v-row dense>
                <v-col cols="9">
                    <persona-selection class="mb-4"></persona-selection>
                </v-col>
                <v-col class="mt-5">
                    <tooltip :text="tooltips.persona_selection"></tooltip>
                </v-col>
            </v-row> -->
            <div class="pl-6 pr-6 pt-2">
                <v-row dense>
                    <v-col cols="10"> 
                        <v-slider
                            v-model="similarity"
                            :tick-labels="similarity_labels"
                            max="10"
                            min="-10"
                            thumb-label="always"
                            :thumb-size="19"
                            step="1"
                            ticks
                            :color="slider_color"
                            :track-color="track_color"
                            :thumb-color="thumb_color"
                            @change="setRefreshDisabled(false)"
                            :readonly="readOnly"  
                                     
                        >
                            <template v-slot:thumb-label="{ value }">
                                {{thumb_labels[value+10]}}
                            </template>
                        </v-slider>
                    </v-col>
                    
                    <v-col>
                        <v-tooltip 
                            left
                            :max-width="this.tooltip_width"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon 
                                    v-bind="attrs"
                                    v-on="on"
                                    color="var(--contrast-color)"
                                >
                                    mdi-help-circle-outline
                                </v-icon>
                            </template>
                            <span>
                                {{this.tooltips.similarity}}
                            </span>
                        </v-tooltip>
                    </v-col>
                </v-row>
          
                <v-row dense>
                    <v-col cols="10">
                        <v-slider
                            v-model="positivity"
                            :tick-labels="positivity_labels"
                            max="10"
                            min="-10"
                            thumb-label="always"
                            :thumb-size="19"
                            step="1"
                            ticks
                            :color="slider_color"
                            :track-color="track_color"
                            :thumb-color="thumb_color"
                            @change="setRefreshDisabled(false)"
                            :readonly="readOnly"  
                            
                        >
                            <template v-slot:thumb-label="{ value }">
                                {{thumb_labels[value+10]}}
                            </template>
                        </v-slider>
                    </v-col>
                    <v-col>  
                        <v-tooltip 
                            left
                            :max-width="this.tooltip_width"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon 
                                    v-bind="attrs"
                                    v-on="on"
                                    color="var(--contrast-color)"
                                >
                                    mdi-help-circle-outline
                                </v-icon>
                            </template>
                            <span>
                                {{this.tooltips.positivity}}
                            </span>
                        </v-tooltip>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="10">
                        <v-slider
                            v-model="hard_news"
                            :tick-labels="political_labels"
                            max="10"
                            min="-10"
                            thumb-label="always"
                            :thumb-size="19"
                            step="1"
                            ticks
                            :color="slider_color"
                            :track-color="track_color"
                            :thumb-color="thumb_color"
                            @change="setRefreshDisabled(false)"
                            :readonly="readOnly" 
                             
                        >
                            <template v-slot:thumb-label="{ value }">
                                {{thumb_labels[value+10]}}
                            </template>                        
                        </v-slider>
                    </v-col>
                    <v-col>  
                        <v-tooltip 
                            left
                            :max-width="this.tooltip_width"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon 
                                    v-bind="attrs"
                                    v-on="on"
                                    color="var(--contrast-color)"
                                >
                                    mdi-help-circle-outline
                                </v-icon>
                            </template>
                            <span>
                                {{this.tooltips.political}}
                            </span>
                        </v-tooltip>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="10">
                        <v-slider
                            v-model="subjectivity"
                            :tick-labels="subjectivity_labels"
                            max="10"
                            min="-10"
                            thumb-label="always"
                            :thumb-size="19"
                            step="1"
                            ticks
                            :color="slider_color"
                            :track-color="track_color"
                            :thumb-color="thumb_color"
                            @change="setRefreshDisabled(false)"
                            :readonly="readOnly"  
                            
                        >
                            <template v-slot:thumb-label="{ value }">
                                {{thumb_labels[value+10]}}
                            </template>
                        </v-slider>
                    </v-col>
                    <v-col>  
                        <v-tooltip 
                            left
                            :max-width="this.tooltip_width"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon 
                                    v-bind="attrs"
                                    v-on="on"
                                    color="var(--contrast-color)"
                                >
                                    mdi-help-circle-outline
                                </v-icon>
                            </template>
                            <span>
                                {{this.tooltips.subjectivity}}
                            </span>
                        </v-tooltip>
                    </v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="10">
                        <v-slider
                            v-model="actuality"
                            :tick-labels="actuality_labels"
                            max="10"
                            min="-10"
                            thumb-label="always"
                            :thumb-size="19"
                            step="1"
                            ticks
                            :color="slider_color"
                            :track-color="track_color"
                            :thumb-color="thumb_color"
                            @change="setRefreshDisabled(false)"
                            :readonly="readOnly"  
                            
                        >
                            <template v-slot:thumb-label="{ value }">
                                {{thumb_labels[value+10]}}
                            </template>
                        </v-slider>
                    </v-col>
                    <v-col>  
                        <v-tooltip 
                            left
                            :max-width="this.tooltip_width"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon 
                                    v-bind="attrs"
                                    v-on="on"
                                    color="var(--contrast-color)"
                                >
                                    mdi-help-circle-outline
                                </v-icon>
                            </template>
                            <span>
                                {{this.tooltips.actuality}}
                            </span>
                        </v-tooltip>
                    </v-col>
                </v-row>

                <v-row class="pa-6" dense>
                    <v-col cols="10">
                        <v-text-field
                        v-model="description"
                        prefix="Request:"
                        compact
                        outlined
                        maxlength="40"
                        counter
                        @change="updateDescription()"
                        ></v-text-field>
                    </v-col>
                    <v-col class="mt-4">  
                         <v-tooltip 
                            left
                            :max-width="this.tooltip_width"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon 
                                    v-bind="attrs"
                                    v-on="on"
                                    color="var(--contrast-color)"
                                >
                                    mdi-help-circle-outline
                                </v-icon>
                            </template>
                            <span>
                                {{this.tooltips.description}}
                            </span>
                        </v-tooltip>                       
                    </v-col>
                </v-row>                
                <v-btn class="mb-5" @click="updateSettings" block outlined color="var(--primary-color)" :disabled="this.getRefreshDisabled">
                    更新</v-btn>
            </div>


            <!-- <v-snackbar
                v-model="snackbar"
                :timeout="timeout"
                color="var(--contrast-color)"
                outlined
                >
                {{ text }}

                <template v-slot:action="{ attrs }">
                    <v-btn
                    color="var(--contrast-color)"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                    >
                    Close
                    </v-btn>
                </template>
            </v-snackbar> -->
        </div>
    <!-- </v-card> -->
</template>


<script>

import { getAPI } from "@/axios";
import { mapActions, mapGetters } from 'vuex';


export default {

    data: () => ({
        positivity:0,
        hard_news:0,
        subjectivity:0,
        similarity:0,
        actuality:0,
        snackbar:"false",
        timeout: 2000,
        text:"Flux d'articles actualisé",
        refresh_disabled:true,
        description: "",
        readOnly: true,
        slider_color: "var(--primary-color)",
        track_color: "var(--primary-color)",
        thumb_color:"var(--primary-color)",
        thumb_labels: ["10","9","8","7","6","5","4","3","2","1","0","1","2","3","4","5","6","7","8","9","10"],
        similarity_labels: ["Dissimilar","","","","","","","","","","","","","","","","","","","","Similar"],
        positivity_labels: ["Negative","","","","","","","","","","","","","","","","","","","","Positive"],
        political_labels: ["Soft news","","","","","","","","","","","","","","","","","","","","Hard news"],
        subjectivity_labels: ["Subjective","","","","","","","","","","","","","","","","","","","","Objective"],
        actuality_labels: ["Old","","","","","","","","","","","","","","","","","","","","New"],
        tooltips:{
            persona_selection:"それぞれのアルゴリズムにキャラクターがあります。お気に入りを一つ選んでください。",
            similarity:"類似性の度合いはあなたの閲読履歴を調べて測定します。閲読履歴に類似した記事とは、あなたがこのウェブサイトでよくチェックしているテーマを扱っているものです。",
            positivity:"肯定的な出来事の記事か、否定的な出来事の記事かを選択できます。",
            political:"硬いニュースとは政治や経済を扱うものが多く、ソフトなニュースとはスポーツ、文化、音楽など軽い内容のものです。",
            subjectivity:"もっと事実に基づいた記事を読みたいか、主観的なものを読みたいかを選択できます。",
            actuality:"最近の記事を読みたいか、古い記事を読みたいかを選択できます。",
            description:"ここではChatGPTを用いており、より詳細なレコメンデーションを入力できます。たとえば「音楽やゲームの記事が読みたい」などと入力してみてください。上記の設定条件もここに反映されます。ただしアルゴリズムは上手に記事を見つけられない場合がありますので、あらかじめご了承ください。"
        },
        tooltip_width:"250"

    }),

    props: {
        update:String,
    },
    
    computed: {
       ...mapGetters([
            'getArticles',
            'getSettings',
            'getRefreshDisabled',
            'getPersonaMessage',
            'getNewPersona'
        ]),


    },
    methods: {
        ...mapActions([
            'setListArticles',
            'setArticlesIdArray',
            'setListAllArticles',
            'setSettings',
            'setArticlesStatus',
            'setRefreshDisabled',
            'setPersonaMessage',
            'setNewPersona',
            'setMonitoringStatus'

        ]),

        updateDescription() {
            let localSettings = this.getSettings
            localSettings["description"] = this.description
            this.setSettings(localSettings)

            //let settings = []
            //let descr_setting={}
            //descr_setting.setting_group = "recommender"
            //descr_setting.setting = "description"
            //descr_setting.value = this.description
            //settings.push(descr_setting)

            this.setRefreshDisabled(false)

            //getAPI.post("update_multiple_settings", {
            //    params: settings
            //})
            //.catch(err => {
            //    console.log(err);
            //});
            
        },

        updateSettings() {
            if (window.matchMedia("(max-width: 960px)")) {
                this.setMonitoringStatus(false)
            }
            let localSettings = this.getSettings
            let settings = []

            let sim_setting={}
            sim_setting.setting_group = "recommender"
            sim_setting.setting = "similarity"
            sim_setting.value = this.similarity
            settings.push(sim_setting)
            localSettings["recommender"]["similarity"] = this.similarity

            let pos_setting={}
            pos_setting.setting_group = "recommender"
            pos_setting.setting = "positivity"
            pos_setting.value = this.positivity
            settings.push(pos_setting)
            localSettings["recommender"]["positivity"] = this.positivity

            let pol_setting={}
            pol_setting.setting_group = "recommender"
            pol_setting.setting = "hard_news"
            pol_setting.value = this.hard_news
            settings.push(pol_setting)
            localSettings["recommender"]["hard_news"] = this.hard_news

            let subj_setting={}
            subj_setting.setting_group = "recommender"
            subj_setting.setting = "subjectivity"
            subj_setting.value = this.subjectivity
            settings.push(subj_setting)
            localSettings["recommender"]["subjectivity"] = this.subjectivity

            let actu_setting={}
            actu_setting.setting_group = "recommender"
            actu_setting.setting = "actuality"
            actu_setting.value = this.actuality
            settings.push(actu_setting)
            localSettings["recommender"]["actuality"] = this.actuality

            let persona_setting = {}
            persona_setting.setting_group = "recommender"
            persona_setting.setting = "persona"
            persona_setting.value = this.getSettings.recommender.persona
            settings.push(persona_setting)

            let description_setting = {}
            description_setting.setting_group = "recommender"
            description_setting.setting ="description"
            description_setting.value = this.description
            settings.push(description_setting)

            // let descr_setting={}
            // descr_setting.setting_group = "recommender"
            // descr_setting.setting = "description"
            // descr_setting.value = this.description
            // settings.push(descr_setting)
            // localSettings["recommender"]["description"] = this.description

            this.setSettings(localSettings)
            this.setRefreshDisabled(true)
            
            getAPI.post("update_multiple_settings", {
                params: settings
            })
            .then(this.refresh_feed())
            .catch(err => {
                console.log(err);
            });
            

        },

        
        refresh_feed () {
            this.setArticlesStatus(false)
            getAPI.post("refresh_feed", {
                params: {
                    settings_group:"recommender",
                    positivity:this.getSettings["recommender"]["positivity"],
                    hard_news:this.getSettings["recommender"]["hard_news"],
                    subjectivity:this.getSettings["recommender"]["subjectivity"],
                    similarity:this.getSettings["recommender"]["similarity"],
                    actuality:this.getSettings["recommender"]["actuality"],
                    description:this.getSettings["description"]
                }
            })
            .then(response =>{
                let articles_array = response.data.feed;
                let state = response.data.state;
                if (state === "chatgpt-fail") {
                    this.setPersonaMessage("ご要望の記事が見つからなかったので、私のいつものやり方で記事を選んでみました")
                }
                else if (state === "chatgpt-success") {
                    this.setPersonaMessage("ご要望に合う新しい記事を選んでみました")
                }
                else if (state === "basic-success") {
                    this.setPersonaMessage("ご興味がありそうな記事を見つけました")
                }
                
                var list_articles = [];
                for(let article of articles_array){
                    let element = new Object();
                    element["article"] = article;
                    element["visibility"] = true;
                    list_articles.push(element);
                }
                this.setListArticles(list_articles);
                this.setArticlesStatus(true)
                var array = []
                for(let element of this.getArticles){
                    if (element.visibility){
                    array.push(element.article.id);
                    }
                }
                this.setArticlesIdArray(array);
                this.snackbar = true;
   
            })
        },
    },
    created: function(){
        console.log(this.getNewPersona)
        var settings = this.getSettings
        if (settings["recommender"]){
            if (settings["recommender"]["persona"]=="assistant"){
                this.readOnly=false
                this.track_color = "var(--contrast-color)"
                this.slider_color = "var(--contrast-color)"
                this.thumb_color = "var(--contrast-color)"
            }
            else{
                this.readOnly=true
                this.track_color = "var(--primary-color)"
                this.slider_color = "var(--primary-color)"
                this.thumb_color = "var(--primary-color)"
            }
            this.similarity = settings["recommender"]["similarity"]
            this.positivity = settings["recommender"]["positivity"]
            this.hard_news = settings["recommender"]["hard_news"]
            this.subjectivity = settings["recommender"]["subjectivity"]
            this.actuality = settings["recommender"]["actuality"]
            this.description = settings["description"]

        }
    },
    watch: {
        update: function(val) {
            if (val=="assistant"){
                this.readOnly=false
                this.track_color = "var(--contrast-color)"
                this.slider_color = "var(--contrast-color)"
                this.thumb_color = "var(--contrast-color)"
            }
            else{
                this.readOnly=true
                this.track_color = "var(--primary-color)"
                this.slider_color = "var(--primary-color)"
                this.thumb_color = "var(--primary-color)"
            }
            var settings = this.getSettings
            this.similarity = settings["recommender"]["similarity"]
            this.positivity = settings["recommender"]["positivity"]
            this.hard_news = settings["recommender"]["hard_news"]
            this.subjectivity = settings["recommender"]["subjectivity"]
            this.actuality = settings["recommender"]["actuality"]
            this.description = settings["description"]
        },
  
    }
}
</script>

<style scoped>
.v-snackbar{
    justify-content: center;
}


 ::v-deep .v-slider__tick {
      border-radius:5px;
      background-color:var(--scrollbar-color)!important;

  }

  ::v-deep .v-label {
      width: 90px;
  }



</style>