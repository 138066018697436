<template>
    <div class="container-card" id="container">
        
        <v-alert
        v-if="parent==='search'"
        border="left"
        outlined
        icon="mdi-magnify"
        >
            Résultats de la recherche de mots clés : 
        </v-alert>

   
        <div id="tour-home-step-flux" v-for="element in this.filtered_articles" :key="element.article.id" >
            <v-card id="cardWrapper" @click="openDialog(element.article)" v-if="element.visibility" v-bind:class="{searchresult: parent==='search'}" elevation="1" class="mb-3" hover outlined >
                <div  class="d-flex flex-no-wrap justify-space-between">
                    <div>
                        <v-card-title
                        class="text-h6 font-weight-regular"
                        >
                            {{element.article.translated_title}}
                        </v-card-title>
                        <div class="text-subtitle-1 ml-4 ">
                            <span class=" font-weight-medium">元のタイトル: </span>
                            <span >{{element.article.title}}</span>
                        </div>
                        <div class="text-subtitle-1 ml-4">
                            <span  class="font-weight-medium">出版元: </span>
                            <span>{{element.article.created_at}}</span>
                        </div>
                        <div class="text-subtitle-1 ml-4">
                            <span  class="font-weight-medium">情報源:  </span>
                            <span>{{element.article.signature}}</span>
                        </div>
                        
                        <!-- <v-card-subtitle v-html="getArticleContent(element.article.translation)">{{getArticleContent(element.article.content)}}</v-card-subtitle> -->

                        <v-row no-gutters class="ml-1 mr-1 mb-2">
                            <div v-for="(score,i) in element.article.scores" :key="i">
                                <v-btn
                                class="ml-2 mt-5"
                                outlined    
                                rounded
                                small
                                color="var(--primary-color)">
                                    {{score}}
                                </v-btn>
                            </div>
                        </v-row>

                    </div>

                    <v-img v-resize="onResize" class="avatar"  :width="width" :src="element.article.image_url"></v-img>
                    
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getAPI } from "@/axios";

export default {

    props: {
        articles: Array,
        parent:String,
    },

    data: () => ({
      width:0,
      height:0,
    }),
 
    computed: {
       ...mapGetters([
            'getArticles',
            'getMaxArticlesDisplayed',
            'getSettings',
            'getRefreshDisabled',
        ]),
        filtered_articles() {
            const max_articles = this.getMaxArticlesDisplayed
            let processed_articles = []
            let count = 0
            for (let element of this.articles) {
                if (count >= max_articles) {
                    break
                }
                else if(element.visibility){
                    processed_articles.push(element)
                    count ++                    
                }
            }
            return processed_articles
        },
        
        
    },
    methods: {
        ...mapActions([
            'setCurrentArticle',
            'setOpenDialogArticle',
            'setListArticles',
            'setLineChartData'
        ]),
        getArticleContent(content){
            var article_string = '';
            if (Array.isArray(content)){
                for(let i=0; i<=content.length;i++){
                    if(content[i]){
                        if(content[i].type == 'TEXT'){
                            article_string = content[i].content.replace( /(<([^>]+)>)/ig, '');
                            break;
                        }
                    }
                }
            }
            else{
                article_string = content
            }

            return article_string.substring(0, 200) + "...";
        },
        

        openDialog(selected_article){
            this.setCurrentArticle(selected_article);
            this.setOpenDialogArticle(true);
            if (this.parent == "home" || this.parent == "search"){
                 getAPI.post("create_reading", {
                params: {
                  article_id: selected_article.id
                }
              })
              .then()
              .catch(err => {
                console.log(err);
              });
            }
        },
        scrollToTop() {
            var container = document.getElementById("container");
            container.scrollIntoView({behavior:"smooth",block:"start"});
        },

        wrapperHeight(){
            const element = document.getElementById('cardWrapper')
            if (element) {
                const height = element.offsetHeight + 80
                return height
            }
            else {
                return 0
            }
            
        },

        wrapperWidth(){
            const element = document.getElementById('cardWrapper')
            if (element) {
                const width = element.offsetWidth / 4
                return width
            }
            else {
                return 0
            }
        },

        onResize(){
            this.height = this.wrapperHeight()
            this.width = this.wrapperWidth()

        },

        deleteReading(article_id){
            //update local list
            let count = 0
            for (let element of this.articles){
                if (element.article.id == article_id) {
                    break
                }
                count++ 
            }
            this.articles.splice(count,1)

            //delete in the db and then update linechart data
            getAPI.post("delete_reading", {
                params: {
                  article_id: article_id
                }
            })
            .then( response => {
                if (response.status == 200){
                    setTimeout(() => {}, 1000)
                    getAPI.get("get_reading_count")
                    .then(response =>{
                    this.setLineChartData(response.data)
                    }).catch(err => {
                        console.log(err);
                    })
                }
            })
            .catch(err => {
            console.log(err);
            });

            
        }

        
        
    },

    watch: {
        articles: function (val) {
            this.articles = val;
            this.scrollToTop();
            
        }
    }
}
</script>

<style scoped>
    .container-card{
        scroll-margin-top :6rem;
    }
    .searchresult{
        border: 1px var(--contrast-color) solid !important;
    }
    .v-card{
        margin: 1% auto;
        cursor: pointer;
        user-select: none;
        border: 1px rgb(212, 212, 212) solid;
    }
    .v-card:hover{
        transform: scale(1.01);
    }
    .v-card:active{
        transform: scale(0.98);
    }
    .v-card__text, .v-card__title {
        word-break: normal;
    }

    .v-alert{
        /* background: #008080; */
        /* background: #fc813b; */
        color: #ED5800;
    }

    .filtered-search{
        border: 1px #ED5800 solid;
    }

    .avatar{
        border-radius:2px;
        border: 0px;
    }

    .del-btn {
        position:absolute;
        right: 5px;
        top: 5px;
    }
</style>