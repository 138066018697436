var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"pl-6 pr-6 pt-2"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-slider',{attrs:{"tick-labels":_vm.similarity_labels,"max":"10","min":"-10","thumb-label":"always","thumb-size":19,"step":"1","ticks":"","color":_vm.slider_color,"track-color":_vm.track_color,"thumb-color":_vm.thumb_color,"readonly":_vm.readOnly},on:{"change":function($event){return _vm.setRefreshDisabled(false)}},scopedSlots:_vm._u([{key:"thumb-label",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.thumb_labels[value+10])+" ")]}}]),model:{value:(_vm.similarity),callback:function ($$v) {_vm.similarity=$$v},expression:"similarity"}})],1),_c('v-col',[_c('v-tooltip',{attrs:{"left":"","max-width":this.tooltip_width},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"var(--contrast-color)"}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(this.tooltips.similarity)+" ")])])],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-slider',{attrs:{"tick-labels":_vm.positivity_labels,"max":"10","min":"-10","thumb-label":"always","thumb-size":19,"step":"1","ticks":"","color":_vm.slider_color,"track-color":_vm.track_color,"thumb-color":_vm.thumb_color,"readonly":_vm.readOnly},on:{"change":function($event){return _vm.setRefreshDisabled(false)}},scopedSlots:_vm._u([{key:"thumb-label",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.thumb_labels[value+10])+" ")]}}]),model:{value:(_vm.positivity),callback:function ($$v) {_vm.positivity=$$v},expression:"positivity"}})],1),_c('v-col',[_c('v-tooltip',{attrs:{"left":"","max-width":this.tooltip_width},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"var(--contrast-color)"}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(this.tooltips.positivity)+" ")])])],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-slider',{attrs:{"tick-labels":_vm.political_labels,"max":"10","min":"-10","thumb-label":"always","thumb-size":19,"step":"1","ticks":"","color":_vm.slider_color,"track-color":_vm.track_color,"thumb-color":_vm.thumb_color,"readonly":_vm.readOnly},on:{"change":function($event){return _vm.setRefreshDisabled(false)}},scopedSlots:_vm._u([{key:"thumb-label",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.thumb_labels[value+10])+" ")]}}]),model:{value:(_vm.hard_news),callback:function ($$v) {_vm.hard_news=$$v},expression:"hard_news"}})],1),_c('v-col',[_c('v-tooltip',{attrs:{"left":"","max-width":this.tooltip_width},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"var(--contrast-color)"}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(this.tooltips.political)+" ")])])],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-slider',{attrs:{"tick-labels":_vm.subjectivity_labels,"max":"10","min":"-10","thumb-label":"always","thumb-size":19,"step":"1","ticks":"","color":_vm.slider_color,"track-color":_vm.track_color,"thumb-color":_vm.thumb_color,"readonly":_vm.readOnly},on:{"change":function($event){return _vm.setRefreshDisabled(false)}},scopedSlots:_vm._u([{key:"thumb-label",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.thumb_labels[value+10])+" ")]}}]),model:{value:(_vm.subjectivity),callback:function ($$v) {_vm.subjectivity=$$v},expression:"subjectivity"}})],1),_c('v-col',[_c('v-tooltip',{attrs:{"left":"","max-width":this.tooltip_width},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"var(--contrast-color)"}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(this.tooltips.subjectivity)+" ")])])],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-slider',{attrs:{"tick-labels":_vm.actuality_labels,"max":"10","min":"-10","thumb-label":"always","thumb-size":19,"step":"1","ticks":"","color":_vm.slider_color,"track-color":_vm.track_color,"thumb-color":_vm.thumb_color,"readonly":_vm.readOnly},on:{"change":function($event){return _vm.setRefreshDisabled(false)}},scopedSlots:_vm._u([{key:"thumb-label",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.thumb_labels[value+10])+" ")]}}]),model:{value:(_vm.actuality),callback:function ($$v) {_vm.actuality=$$v},expression:"actuality"}})],1),_c('v-col',[_c('v-tooltip',{attrs:{"left":"","max-width":this.tooltip_width},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"var(--contrast-color)"}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(this.tooltips.actuality)+" ")])])],1)],1),_c('v-row',{staticClass:"pa-6",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"prefix":"Request:","compact":"","outlined":"","maxlength":"40","counter":""},on:{"change":function($event){return _vm.updateDescription()}},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('v-col',{staticClass:"mt-4"},[_c('v-tooltip',{attrs:{"left":"","max-width":this.tooltip_width},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"var(--contrast-color)"}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(this.tooltips.description)+" ")])])],1)],1),_c('v-btn',{staticClass:"mb-5",attrs:{"block":"","outlined":"","color":"var(--primary-color)","disabled":this.getRefreshDisabled},on:{"click":_vm.updateSettings}},[_vm._v(" 更新")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }