import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from './views/Home';
import Fork from './views/Fork';
//import Discovery from './views/Discovery';
import Admin from './views/Admin';
import Login from './views/Login';
//import About from './views/About';
// import Help from './views/Help';
// import Profile from './views/Profile';
import Agreements from './views/Agreements';

import { getAPI } from "@/axios";

Vue.use(VueRouter);

function check_authentication(to, from, next) {
    getAPI.get("is_authenticated")

            .then((response) => {
                const authenticated = response.data.access;
                const consent = response.data.consent;
                if (to.name === "discovery" && !authenticated){
                    next();
                }
                else if (authenticated && !consent){
                    next('/agreements');
                }
                else if (authenticated && consent){
                    if (to.name === "discovery"){
                        next('/home');
                    }
                    else {
                        next();
                    }   
                }
                else if (!authenticated){
                    next('/');
                }

            })
            .catch((error) => {
                if (error.response.status == 401) {
                    next('/');
                }
            })
}

function check_admin(to, from, next) {
    getAPI.get("is_admin")
        .then((response) => {
            var is_admin = response.status;
            if (is_admin == 200) {
                next();
            }
        })
        .catch((error) => {
            if (error.response.status == 401) {
                next('/home');
            }
        })
}

function check_consent (to, from, next) {
    getAPI.get("is_authenticated")
        .then((response) => {
            const authenticated = response.data.access;
            const consent = response.data.consent;
            if (authenticated && !consent) {
                next();
            }
            else if (authenticated && consent) {
                next('/home');
            }
            else if (!authenticated) {
                next('/');
            }

        })
        .catch((error) => {
            if (error.response.status == 401) {
                next('/');
            }
        })
}

export default new VueRouter({
    mode: 'history',
    routes: [{
            path: '/home',
            name: 'home',
            component: Home,
            beforeEnter: check_authentication
        },
        {
            path: '/',
            name: 'fork',
            component: Fork,
        },
        // {
        //     path: '/about',
        //     name: 'about',
        //     component: About,
        // },
        // {
        //     path: '/discovery',
        //     name: 'discovery',
        //     component: Discovery,
        //     beforeEnter: check_authentication
        // },
        {
            path: '/admin',
            name: 'admin',
            component: Admin,
            beforeEnter: check_admin
        },
        {
            path: '/login/:token',
            name: 'login',
            component: Login,
            props: true,
            // redirect: '/'
        },
        // {
        //     path: '/help',
        //     name: 'help',
        //     component: Help,
        //     beforeEnter: check_authentication
        // },
        // {
        //     path: '/profile',
        //     name: 'profile',
        //     component: Profile,
        //     beforeEnter: check_authentication
        // },
       
        {
            path: '/agreements',
            name: 'agreements',
            component: Agreements,
            beforeEnter: check_consent
        },
    ],
})