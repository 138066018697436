import * as types from '../mutation-types'

const default_settings = {
        "filtering": {"Info": true, "Sport": true, "Culture & Musique": true, "Actualités locales": true, "Santé & Bien-être": true, "Sciences et Techno": true, "Environnement": true, "Vie pratique": true},
        "history": {"Info": true, "Sport": true, "Culture & Musique": true, "Actualités locales": true, "Santé & Bien-être": true, "Sciences et Techno": true, "Environnement": true, "Vie pratique": true, "minimum":null, "maximum": null, "profile":"news"},
        "recommender":{
            "similarity":10,
            "actuality":10,
            "subjectivity":10,
            "objectivity":10,
            "hard_news":10,
            "soft_news":10,
            "positivity":10,
            "negativity":10,
            "persona":"butler"
        },
        "access":{
            "first_session":true,
            "profile_first_session":true
        }
}


const state = {
    articles_status: false, //monitoring the validity of the articles loaded
    settings_status: false, //monitoring the validity of the settings loaded
    articles: [],
    articlesUnordered: [],
    history: [],
    maxArticlesDisplayed: 100,
    searchResults: [],
    currentArticle: null,
    openDialogArticle: false,
    articlesIdArray: [],
    users: [],
    settings: default_settings,
    userEmail: null,
    userEmailHash: null,
    firstSessionCategories: [],
    lineChartData: null,
    chartColors:{},
    searchStatus : false,
    monitoringStatus : false,
    wordCloudSearch: "",
    filteringUpdate:"",
    refreshDisabled:true, //enabling refresh button when new settings
    personaMessage:"",
    newPersona:"",
}

const getters = {
    getArticlesStatus: state=> state.articles_status,
    getSettingsStatus: state => state.settings_status,
    getArticles: state => state.articles,
    getArticlesUnordered: state => state.articlesUnordered,
    getHistory: state => state.history,
    getMaxArticlesDisplayed: state => state.maxArticlesDisplayed,
    getSearchResults: state => state.searchResults,
    getCurrentArticle: state => state.currentArticle,
    getOpenDialogArticle: state => state.openDialogArticle,
    getArticlesIdArray: state => state.articlesIdArray,
    getUsers: state => state.users,
    getSettings: state => state.settings,
    getUserEmail: state => state.userEmail,
    getUserEmailHash: state => state.userEmailHash,
    getFirstSessionCategories: state => state.firstSessionCategories,
    getLineChartData: state => state.lineChartData,
    getChartColors: state => state.chartColors,
    getSearchStatus: state => state.searchStatus,
    getMonitoringStatus: state => state.monitoringStatus,
    getWordCloudSearch: state => state.wordCloudSearch,
    getFilteringUpdate: state => state.filteringUpdate,
    getRefreshDisabled: state => state.refreshDisabled,
    getPersonaMessage: state => state.personaMessage,
    getNewPersona: state => state.newPersona,
}

const actions = {
    setArticlesStatus({ commit }, data) {
        commit(types.SET_ARTICLES_STATUS, data)
    },
    setSettingsStatus({ commit }, data) {
        commit(types.SET_SETTINGS_STATUS, data)
    },
    setListArticles({ commit }, data) {
        commit(types.REQUEST_FETCH_ARTICLES, data)
    },
    setListArticlesUnordered({ commit }, data) {
        commit(types.REQUEST_FETCH_ARTICLES_UNORDERED, data)
    },
    setMaxArticlesDisplayed({ commit }, data) {
        commit(types.SET_MAX_ARTICLES_DISPLAYED, data)
    },
    setHistory({ commit }, data) {
        commit(types.REQUEST_FETCH_HISTORY, data)
    },
    setSearchResults({ commit }, data) {
        commit(types.SET_SEARCH_RESULTS, data)
    },
    setCurrentArticle({ commit }, data) {
        commit(types.SET_CURRENT_ARTICLE, data)
    },
    setOpenDialogArticle({ commit }, data) {
        commit(types.SET_OPEN_DIALOG_ARTICLE, data)
    },
    setArticlesIdArray({ commit }, data) {
        commit(types.SET_ARTICLES_ID_ARRAY, data)
    },
    setUsers({ commit }, data) {
        commit(types.SET_USERS, data)
    },
    setSettings({ commit }, data) {
        commit(types.SET_FETCH_SETTINGS, data)
    },
    setUserEmail({ commit }, data) {
        commit(types.SET_USER_EMAIL, data)
    },
    setUserEmailHash({ commit }, data) {
        commit(types.SET_USER_EMAIL_HASH, data)
    },
    setFirstSessionCategories({ commit }, data) {
        commit(types.SET_FIRST_SESSION_CATEGORIES, data)
    },
    setFirstSessionCategoriesAll({ commit }, data) {
        commit(types.SET_FIRST_SESSION_CATEGORIES_ALL, data)
    },
    setLineChartData({ commit }, data) {
        commit(types.SET_LINE_CHART_DATA, data)
    },
    setChartColors({commit},data){
        commit(types.SET_CHART_COLORS,data)
    },
    setSearchStatus({ commit }, data) {
        commit(types.SET_SEARCH_STATUS, data)
    },
    setMonitoringStatus({ commit }, data) {
        commit(types.SET_MONITORING_STATUS, data)
    },
    setWordCloudSearch({ commit }, data) {
        commit(types.SET_WORD_CLOUD_SEARCH, data)
    },
    setFilteringUpdate({ commit }, data) {
        commit(types.SET_FILTERING_UPDATE, data)
    },
    setRefreshDisabled({ commit }, data) {
        commit(types.SET_REFRESH_DISABLED, data)
    },
    setPersonaMessage({ commit }, data) {
        commit(types.SET_PERSONA_MESSAGE, data)
    },
    setNewPersona({ commit }, data) {
        commit(types.SET_NEW_PERSONA, data)
    }

}

const mutations = {
    [types.SET_ARTICLES_STATUS](state, data) {
        state.articles_status = data
    },
    [types.SET_SETTINGS_STATUS](state, data) {
        state.settings_status = data
    },
    [types.REQUEST_FETCH_ARTICLES](state, data) {
        state.articles = data
    },
    [types.REQUEST_FETCH_ARTICLES_UNORDERED](state, data) {
        state.articlesUnordered = data
    },
    [types.REQUEST_FETCH_HISTORY](state, data) {
        state.history = data
    },
    [types.SET_MAX_ARTICLES_DISPLAYED](state, data) {
        state.maxArticlesDisplayed = data
    },
    [types.SET_SEARCH_RESULTS](state, data) {
        state.searchResults = data
    },
    [types.SET_CURRENT_ARTICLE](state, data) {
        state.currentArticle = data
    },
    [types.SET_OPEN_DIALOG_ARTICLE](state, data) {
        state.openDialogArticle = JSON.parse(data)
    },
    [types.SET_ARTICLES_ID_ARRAY](state, data) {
        state.articlesIdArray = data
    },
    [types.SET_USERS](state, data) {
        state.users = data
    },
    [types.SET_FETCH_SETTINGS](state, data) {
        state.settings = data
    },
    [types.SET_USER_EMAIL](state, data) {
        state.userEmail = data
    },
    [types.SET_USER_EMAIL_HASH](state, data) {
        state.userEmailHash = data
    },
    [types.SET_FIRST_SESSION_CATEGORIES](state, data) {
        state.firstSessionCategories.push(data)
    },
    [types.SET_FIRST_SESSION_CATEGORIES_ALL](state, data) {
        state.firstSessionCategories = data
    },
    [types.SET_LINE_CHART_DATA](state, data) {
        state.lineChartData = data
    },
    [types.SET_CHART_COLORS](state, data) {
        state.chartColors = data
    },
    [types.SET_SEARCH_STATUS](state, data) {
        state.searchStatus = data
    },
    [types.SET_MONITORING_STATUS](state, data) {
        state.monitoringStatus = data
    },
    [types.SET_WORD_CLOUD_SEARCH](state, data) {
        state.wordCloudSearch = data
    },
    [types.SET_FILTERING_UPDATE](state, data) {
        state.filteringUpdate = data
    },
    [types.SET_REFRESH_DISABLED](state, data) {
        state.refreshDisabled = data
    },
    [types.SET_PERSONA_MESSAGE](state, data) {
        state.personaMessage = data
    },
    [types.SET_NEW_PERSONA](state, data) {
        state.newPersona = data
    },

}

export default {
    state,
    getters,
    actions,
    mutations
}