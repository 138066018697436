<template>
    <v-sheet>
        <v-col dense>
            <v-row  class="pt-5" >
                <v-carousel
                    v-model="personaID"
                    :continuous="true"
                    :show-arrows="true"
                    height="220"
                    hide-delimiter-background
                    @change="updateSettings($event)"
                    >
                    <v-carousel-item
                        v-for="(persona, i) in personas"
                        :key="i"
                    >
                        <v-layout justify-center>
                            <v-btn
                                outlined
                                rounded
                                class="disable-events"
                                color="var(--primary-color)"
                                >
                                {{names[i]}}
                            </v-btn>
                        </v-layout>
                         <v-img
                            contain
                            height="180"
                            :src="getUrl(personas[i])"
                        ></v-img>
                    </v-carousel-item>
                </v-carousel>
            </v-row>
            <v-row>
                <v-alert
                    color="var(--primary-color)"
                    outlined
                    shaped
                    width="70vh"
                    class="mr-2 ml-2 overflow-auto"
                >
                    <span v-html="this.displayedMessage" id="message" v-if="this.showMessage">
                    </span>
                </v-alert>
            </v-row>
        </v-col>
    </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    components: {
    },
    data: () => ({
        showMessage:true,
        message:[],
        speed: 40,
        textPosition: 0,
        displayedMessage:"",
        timeouts:[],
        personaID:"",
        names: [
            "Echo, Copycat",
            "April, Reporter",
            "Miro, Artist",
            "Nobel, Scientist",
            "Alfred, Assistant",
        ],
        personas: [
            "copycat",
            "reporter",
            "artist",
            "scientist",
            "assistant"
        ],
        persona_mapping: {
            "copycat": 0,
            "reporter":1,
            "artist":2,
            "scientist": 3,
            "assistant": 4
        },
        persona_settings: {
          "copycat": {"similarity": 10, "positivity": 10, "hard_news":0, "subjectivity": 5, "actuality":5},
          "reporter":{"similarity": -10, "positivity": 5, "hard_news":10, "subjectivity": -5, "actuality":10}, 
          "artist":{"similarity": 0, "positivity": 10, "hard_news":-10, "subjectivity": 5, "actuality":4},
          "scientist":{"similarity": 3, "positivity": 0, "hard_news":0, "subjectivity": -10,"actuality":4}, 
      },
        messages: [
          "I will observe your behaviour and I will look for the kind of articles you usually enjoy.",
          "As a reporter, I will do my best to provide you with hard news that you would not usually read.",
          "I do not like politics, economics and other serious topics. I prefer to present you with light, entertaining articles.",
          "As a thorough scientist, I will select the most objective and factual news articles I can find.",
          "Tell me your preferences and I'll do my best to meet your expectations."
        ],
        
    }),

    computed: {
       ...mapGetters([
            'getPersonaMessage',
            'getSettings'
        ]),


    },
    methods: {
        ...mapActions([
            'setSettings',
            'setRefreshDisabled',
            'setPersonaMessage',
            'setNewPersona'
        ]),
        typewriter() {
            this.displayedMessage = this.message[0].substring(0, this.textPosition) +'<span class="typewriter">|</span>'
            if (this.textPosition++ != this.message[0].length) {
                this.timeouts.push(setTimeout(this.typewriter, this.speed))
            }
        },
        getUrl(persona) {
            //var persona = this.getSettings["recommender"]["persona"]
            if (persona == "copycat") {
                return require("../assets/persona/custom.png")
            }
            else if (persona == "reporter") {
                return require("../assets/persona/reporter.png")
            }
            else if (persona == "artist") {
                return require("../assets/persona/artist2.png")
            }

            else if (persona == "scientist") {
                return require("../assets/persona/scientist.png")
            }
            else {
                return require("../assets/persona/butler.png")
            } 
        },
        updateSettings(event){
            //   add here message change
            this.setRefreshDisabled(false)
            var settings = this.getSettings;
            var persona = this.personas[event]
            settings["recommender"]["persona"] = persona
            if (persona != "assistant") {
                settings["recommender"]["subjectivity"]= this.persona_settings[persona]["subjectivity"]
                settings["recommender"]["hard_news"]= this.persona_settings[persona]["hard_news"]
                settings["recommender"]["positivity"]= this.persona_settings[persona]["positivity"]
                settings["recommender"]["similarity"]= this.persona_settings[persona]["similarity"]
                settings["recommender"]["actuality"]= this.persona_settings[persona]["actuality"]
            }
            this.setSettings(settings);
            this.setPersonaMessage(this.messages[event])
            //this.setNewPersona(persona)

            // getAPI.post("update_settings", {
            // params: {
            //     setting_group: "recommender",
            //     setting: "persona",
            //     value: persona
            // }
            // })
            // .then()
            // .catch(err => {
            //     console.log(err);
            // });
      },

    },
    watch: {
        getPersonaMessage: function(val) {
            this.textPosition = 0
            this.message = [val]
            for (var i=0; i<this.timeouts.length; i++) {
                clearTimeout(this.timeouts[i]);
            }
            this.timeouts=[]
            this.typewriter()
        }
    },
    created: function () {
        this.personaID =  this.persona_mapping[this.getSettings.recommender.persona]
        this.setPersonaMessage(this.messages[this.personaID])
        this.message = [this.getPersonaMessage]
        
        this.typewriter()
    }




}
</script>

<style  >
::v-deep .typewriter{
    animation: blinker 1s linear infinite;
}

@keyframes blinker{
    50%{
        opacity:0;
    }
}

.disable-events {
  pointer-events: none
}

.v-carousel__controls__item{
    color: var(--primary-color) !important
}
.v-btn--active{
    color: var(--contrast-color) !important
}

.v-window__next, .v-window__prev{
    background-color: var(--primary-color) !important
}

</style>
