<template>
    <v-row justify="center">
        <v-dialog
        v-model="dialog"
        scrollable
        persistent

        >
          <v-card >

              <v-card-text v-if="this.block_structure">
                  <div class="container-content" v-for="(block, index) in getCurrentArticle.content" v-bind:key="index">
                      <div v-if="block.type === 'IMAGES'">
                          <img :src="block.content" />
                      </div>
                      <div v-else-if="block.type === 'MEDIA'">
                      </div>
                      <div v-else v-html="block.content">
                          {{block.content}}
                      </div>
                  </div>
              </v-card-text>
              <v-card-text v-else class="container-content">
                                  <div class="headline">{{getCurrentArticle.translated_title}}</div>
                  <div > {{getCurrentArticle.title}}</div>
                <v-col>
                  <v-img v-if="getCurrentArticle.tag === 'image'" :src="getCurrentArticle.image_url" width="100%"  class="mr-auto ml-auto"></v-img>
                  <iframe v-else-if="getCurrentArticle.tag === 'video'" id="ifrm" :src="getCurrentArticle.image_url" width="500" height="500"></iframe>
                </v-col>
                <div>
                  <div class="text-subtitle-2">
                  自動翻訳
                  </div>
                  {{getCurrentArticle.translation}}
                </div>
                <div>
                  <div class="text-subtitle-2">
                  <br>
                  元の記事
                  </div>
                  <div>
                  {{getCurrentArticle.clean_text}}
                  </div>
                </div>

                <div>
                  <br>
                  <b>情報源</b>: {{getCurrentArticle.signature}}
                </div>
                <div>

                </div>

              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                  <v-btn 
                    rounded
                    x-large 
                    elevation="0"  
                    class="white--text" 
                    color="var(--primary-color)" 
                    :href="getCurrentArticle.article_url" 
                    target="_blank">
                    元の記事を参照
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="var(--contrast-color)"
                      @click="closeDialog()"
                      rounded
                      x-large
                      elevation="0"
                      
                  >
                      <v-icon color="white">mdi-close</v-icon>
                  </v-btn>
              </v-card-actions>
          </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

  export default {
    data: () => ({
        dialog: false,
        currentContent: {}
    }),
    created: function () {
        this.dialog = this.getOpenDialogArticle;
        console.log(this.getCurrentArticle);
    },
    computed: {
       ...mapGetters([
            'getOpenDialogArticle',
            'getCurrentArticle'
        ]),
        block_structure(){
          if (Array.isArray(this.getCurrentArticle.content)){
            return true
          }
          else {
            return false
          }
        }
    },
    methods:{
      ...mapActions([
        'setOpenDialogArticle',
      ]),
      closeDialog(){
          this.dialog = false;
          this.setOpenDialogArticle(false);
      },


    },
    watch: {
        getOpenDialogArticle: function (val) {
            this.dialog = val;
        },
    }
  }
</script>

<style scoped>

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #008080;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #008080;
}
::-webkit-scrollbar-thumb:active {
  background: #008080;
}
::-webkit-scrollbar-track {
  background: #ffffff;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-track:hover {
  background: #ffffff;
}
::-webkit-scrollbar-track:active {
  background: #ffffff;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
</style>