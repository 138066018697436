<template>
  <div class="wrapper"  >
    <v-btn
      v-if="$vuetify.breakpoint.smAndDown"
      fab
      absolute
      right
      @click.stop="setDrawerStatus()"
      class = "mr-2 mt-5"
      x-large
      color=var(--contrast-color)
    >
      <v-icon color="white" x-large>
        mdi-account-wrench
      </v-icon>
    </v-btn>
    <v-navigation-drawer
      v-model="this.getMonitoringStatus"
      absolute
      temporary
      floating
      width="100%"
      height="100%"
      v-touch="{
        left: () => swipe('Left'),
        right: () => swipe('Right')
      }"
    >

      <div class="container sort scroller" >
        <v-card outlined class="settings" v-if="this.getSettingsStatus">
          <persona></persona>
          <recommender-transformer :update="this.getSettings.recommender.persona" v-if="this.getSettingsStatus"></recommender-transformer>
        </v-card>
      </div>
    </v-navigation-drawer>
    <div class="container sort scroller" v-if="!$vuetify.breakpoint.smAndDown">
        <v-card outlined class="settings" v-if="this.getSettingsStatus">
          <persona></persona>
          <recommender-transformer :update="this.getSettings.recommender.persona" ></recommender-transformer>
        </v-card>
    </div>
    <div class="container flux flux-mobile scroller">
        <article-card  :articles="this.getArticles" :parent="component_name" v-if="this.getArticlesStatus && !this.getSearchStatus" class="mt-5" ></article-card>
        <article-dialog-box v-if="getOpenDialogArticle"></article-dialog-box>
        <v-layout justify-center>
          <v-progress-circular
          class="mt-16"
          indeterminate
          :size="250"
          :width="15"
          color="var(--primary-color)"
          v-if="!this.getArticlesStatus"
          >
          </v-progress-circular>
        </v-layout>
    </div>
    
  </div>   
</template>

<script>
import ArticleCard from '../components/ArticleCard.vue';
import ArticleDialogBox from '../components/ArticleDialogBox.vue';
import RecommenderTransformer from '../components/RecommenderTransformer.vue';
import Persona from '../components/Persona.vue';
import { getAPI } from "@/axios";
import { mapActions, mapGetters } from 'vuex'

  export default {
    components: {
      ArticleCard,
      ArticleDialogBox,
      RecommenderTransformer,
      Persona
    },
    data () {
      return {
        drawer: false,
        data_tab: null,
        //articles_loaded:false,
        articles_unordered_loaded:false,
        component_name:"home",
        search:"search",
        last_article_read:"",
        }
      },
    computed: {
       ...mapGetters([
            'getOpenDialogArticle',
            'getArticles',
            'getSearchResults',
            'getSearchStatus',
            'getSettings',
            'getSettingsStatus',
            'getArticlesStatus',
            'getNewPersona',
            'getCurrentArticle',
            'getMonitoringStatus'
        ]),
        
        
    },
    methods:{
      ...mapActions([
        'setListArticles',
        'setSettings',
        'setSearchStatus',
        'setSearchResults',
        'setArticlesStatus',
        'setSettingsStatus',
        'setHistory',
        'setPersonaMessage',
        'setMonitoringStatus'

      ]),
      setDrawerStatus() {
        this.setMonitoringStatus(!this.getMonitoringStatus)
      },
      setFirstSession(currentStep){
        console.log(currentStep)
        getAPI.post("update_first_session")
        .then()
        .catch(err => {
          console.log(err)
        })
      },
      isFirstSession(){
          return this.getSettings.access.first_session
      },
      getGptOpinion(){
        var prompt = this.last_article_read
        getAPI.post("get_gpt_opinion",  {
                params: prompt
        }).then(response =>{
          this.setPersonaMessage(response["data"])
        }).catch(err => {
          console.log(err);
        });

      },
      swipe (direction) {
        if (direction == "Left") {
          this.setMonitoringStatus(false)
        }
        else if (direction == "Right") {
          this.setMonitoringStatus(true)
        }
      },

    },
    created: function () {
      this.setSearchStatus(false)
      this.setSearchResults([])
      getAPI.post("settings")
          .then(response =>{
            let results = response;
            let settings = results.data;
            this.setSettings(settings);
            this.setSettingsStatus(true)


            getAPI.get("articles")
              .then(response =>{
                let articles_array = response.data;
                var list_articles = [];
                for(let article of articles_array){
                  let element = new Object();
                  element["article"] = article;
                  element["visibility"] = true;
                  list_articles.push(element);
                }
                this.setListArticles(list_articles);
                this.setArticlesStatus(true);
                //this.articles_loaded = true;
                
              }).catch(err => {
                  console.log(err);
              });
                      
              }).catch(err => {
                  console.log(err);
              });
      },
      // watch:{
      //   getOpenDialogArticle:function(newValue){
      //     if(!newValue){
      //       var random_int = Math.floor((Math.random() * 4)+1)
      //       if ( random_int == 1) {
      //         this.getGptOpinion()
      //       }
      //     }
      //     else {
      //       this.last_article_read=this.getCurrentArticle.title
      //     }
      //   }
      // }
  }
</script>

<style scoped>

  .slide-enter-active{
    transition: all .5s ease;
    transition-delay: 200ms;
  }
  .slide-leave-active {
    transition: all .5s ease;
  }

  .slide-enter, .slide-leave-to {
    transform: translateX(-70px);
    opacity: 0;
  }

  .slide-enter-to, .slide-leave {
    opacity:1;
    transform:translateX(0px);
  }

  .fade-flux-enter-active {
    transition: opacity .5s ease;
    transition-delay: 200ms;
  }
  .fade-flux-leave-active {
    transition: opacity .5s ease;
  }

  .fade-flux-enter, .fade-flux-leave-to {
    opacity: 0;
  }

  .fade-flux-enter-to, .fade-flux-leave {
    opacity:1;
  }



  .v-tour >>> .v-step{
    background:teal!important;
    text-align:justify;
  }



  .settings{
    border-color:var(--primary-color)
  }
  /* .maths{
    width: 30%;
    height: 95vh;
    overflow: scroll;
    position:fixed;
    left:0px;
    top:60px;
  } */

  /* .maths-monitoring{
    width: 30%;
    height: 95vh;
    overflow: scroll;
    position:fixed;
    left:30%;
    top:60px;
  }

  .monitoring{
    width: 30% !important;
  } */

 @media screen and (min-width: 961px) {
    .flux{
      width: 55%;
      right:5%;
      height: 100vh;
      overflow: scroll;
      position: fixed;
    }
 }
  

 @media screen and (max-width: 960px) {
    .flux{
      width: 100%;
      height: 100vh;
      overflow: scroll;
      position: fixed;
    }
 }

 @media screen and (min-width: 961px) {
  .sort{
    width: 35%;
    overflow: scroll;
    height: 100vh;
    position:fixed;
    left:5%;
  }
 }

 @media screen and (max-width: 960px) {
    .sort{
    width: 100%;
    overflow: scroll;
    height: 100%;
    position:fixed;
  }
 }


 .scroller{
    scrollbar-color:var(--scrollbar-color) var(--background-color); 
    scrollbar-width: None;
  }


::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background:var(--scrollbar-color);
  border: 0px none var(--background-color);
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-color);
}
::-webkit-scrollbar-thumb:active {
  background: var(--scrollbar-color);
}
::-webkit-scrollbar-track {
  background: var(--background-color);
  border: 0px none var(--background-color);
  border-radius: 0px;
}
::-webkit-scrollbar-track:hover {
  background: var(--background-color);
}
::-webkit-scrollbar-track:active {
  background: var(--background-color);
}
::-webkit-scrollbar-corner {
  background: transparent;
}


</style>