<template>
    <div class="wrapper">
        <v-container  fluid >
            <v-row align="center" justify="center" class="mt-12">
                <v-col >  
                    <v-card
                    min-height="60vh"
                    width="90vh" 
                    class="mr-10 pl-14 ml-14 main-card" 
                    flat
                    color="transparent"
                    >
                        <p class="name mt-2">ALVEHO</p>

                        <div class="mb-3">
                            Welcome! This website is being developped for the purpose of a joint research between Kansai Universy and UCLouvain.
                            If you are participating in our study but arrived on this page, it means you must log in once again on this website. To do this, you can simply use the connexion link we sent you at the beginning of the study.
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>

export default ({
    components: {

    },
    data () {
        return {

        }
    }
})
</script>

<style scoped>

.wrapper{
    background-image: url("../assets/custom-background-fork.png");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size:cover;
}

.name{
    font-family:"Exo2";
    font-size:35px;;
    color:teal;
    position:relative;
    text-align: center;
    width: 100%;
    cursor: pointer;
}


.model {
    position:relative;
    visibility:hidden;
}

.btn-absolute {
    position:absolute;
}




</style>